.hotline {
    width: 85%;
    margin: 30px auto;
    border: 1px solid var(--pri-col);
    border-radius: 4px;
    box-shadow: 3px 3px 3px gray;
    padding: 13px;
    position: relative;
    bottom: 120px;
    z-index: 9;
    display:flex;
    justify-content: space-between;
    background: var(--sec-col-lite);
}

.hot-loc {
    width: 33%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
}

.hot-loc:nth-child(2) svg {
    font-size: 42px;
    stroke: var(--svg);
    width: 100%;
    padding-bottom: 13px;
    box-sizing: content-box;
    color: var(--svg);
}

.hot-loc:nth-child(1) svg, .hot-loc:nth-child(3) svg {
    font-size: 42px;
    fill: var(--svg);
    width: 100%;
    padding-bottom: 13px;
    box-sizing: content-box;
}

.hot-loc h3 {
    font-size: 15px;
    font-weight: 900;
}

@media(max-width: 500px){
    .hotline {
        bottom: 80px;
    }


    .hot-loc h3 {
        font-size: 10px;
    }

    .hot-loc:nth-child(1) svg,
    .hot-loc:nth-child(3) svg {
        font-size: 26px;
    }

    .hot-loc:nth-child(2) svg {
        font-size: 27px;
    }

    .hot-loc h5 {
        font-size: 8px;
    }
}