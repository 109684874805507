.transfer {
  min-height: 100vh;
  padding-bottom: 21px;
  width: 100%;
  background: var(--pri-col);
}

.transfer * {
  color: #fff;
}

.trans-cont {
  width: 100%;
  min-height: 80vh;
}

.trans-cont form {
  width: 65%;
  margin: 0 auto;
  padding: 13px;
  border: 3px inset #000;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  backdrop-filter: blur(8px);
  box-shadow: 4px 8px 8px 4px #000;
}

.trans-cont form select {
  width: 80%;
}

.trans-cont form select {
  width: 80%;
  background: #03a9f400;
  border-radius: 4px;
  margin: 8px 0;
  padding: 4px;
}

.form-cont.transfer-input {
  width: 80%;
}

.form-cont .form-cont.transfer-input label span {
  color: gray;
  font-size: 12px;
  position: relative;
  bottom: 2px;
}

.form-cont label span {
  color: gray;
  font-size: 13px;
}

form .form-cont.transfer-input input:focus ~ label span,
form .form-cont.transfer-input input:valid ~ label span {
  color: #27af27d3;
  position: relative;
  bottom: 6px;
}

.trans-cont form .total {
  width: 80%;
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 8px 0;
  margin-top: 10px;
}

.trans-cont form .total h3 {
  color: var(--buttoned);
  width: 100%;
  height: 90%;
  text-align: center;
  position: relative;
  border: 1px solid var(--buttoned);
  border-radius: 4px;
}

.trans-cont form .total h3 span {
  font-size: 13px;
  position: absolute;
  top: -12px;
  left: 9px;
  padding: 3px 6px;
  letter-spacing: 3px;
  color: var(--svg);
  border-radius: 4px;
  background: var(--buttoned);
  backdrop-filter: blur(9px);
}

.trans-cont form button {
  width: 80%;
  height: 42px;
  background: var(--svg);
  border: 1px solid #000;
  border-radius: 4px;
  margin: 13px 0;
  cursor: pointer;
}

.trans-cont form button:hover {
  background: #104c7d;
  transition: 1s ease;
}

.pop-up-cont {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #6b90daa8;
  z-index: 99;
  backdrop-filter: blur(6px);
  display: none;
}

.pop-up {
  position: fixed;
  top: 15%;
  left: 25vw;
  width: 50vw;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: #07213d;
  border: 2px solid #9db6e7;
  box-shadow: 2px 4px 4px 2px #000;
  padding: 8px;
  z-index: 999;
}

.openpop {
  display: block !important;
}

.openVen {
  display: flex;
  justify-content: space-around;
}

.pop-up.openVen button {
  width: 50px;
}

.pop-up h5 {
  color: #fff;
  width: 85%;
  text-align: center;
  line-height: 1.8;
  padding-bottom: 6px;
}

.pop-up.openVen button {
  background: crimson;
  border-radius: 3px;
  color: #fff;
  margin-top: 9px;
  border: none;
}

.pop-up.openVen a {
  font-size: 15px;
  padding: 3px 9px;
  border-radius: 3px;
  margin: 4px;
  color: crimson;
  background: #fff;
}

@media (max-width: 800px) {
  .trans-cont form{
    top: 3em;
  }
}
@media (max-width: 500px) {
  .trans-cont form{
    width: 80%;
    top: 3em;
  }

  .trans-cont form select {
    width: 90%;
  }

  .form-cont.transfer-input {
    width: 90%;
}

.trans-cont form .total {
  width: 90%;
}

.trans-cont form button {
  width: 90%;
}
}

@media (max-width: 500px) {
  .trans-cont form {
    width: 85%;
  }

  .pop-up {
    position: fixed;
    top: 10%;
    left: 15vw;
    width: 70vw;
  }

  .pop-up h5 {
    color: #fff;
    width: 90%;
    line-height: 1.5;
  }

  .pop-up.openVen button,
  .pop-up.openVen a {
    width: 50%;
  }

  .pop-up.openVen button {
    background: red;
  }
}
