.trans-header {
    display: grid;
    padding: 4px;
    justify-items: center;
    align-items: end;
}

.trans-header h2 {
    color: #fff;
    text-align: center;
    font-size: 12px;
}

.trans {
    min-height: 100vh;
    padding-bottom: 33px;
    width: 100%;
    background: var(--pri-col);
    color: #000;
    position: relative;
}

.trans-container {
    min-height: 90vh;
    position: relative;
    width: 100vw;
}

.mobile-trans {
    width: 80%;
    margin: 0 auto;
}

.trans-list {
    background: #fff;
    margin-bottom: 18px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-radius: 6px;
    padding: 10px;
    box-shadow: 3px 3px 6px var(--pri-col);
}

.lft {
    display: grid;
    align-content: space-between;
}

.lft h4 {
    color: slategray;
    font-size: 12px;
}

.lft p {
    font-size: 11px;
    font-family: monospace;
    letter-spacing: -1px;
    padding: 10px 0;
    color: #131722;
}

.lft h4.rec-name {
    font-size: 14px;
    color: #0246ff;
}

.cntr {
    width: 40%;
    text-align: center;
}

.cntr h3 {
    padding: 10px 0;
    color: darkgreen;
}

.cntr h3.red {
    color: darkred;
}

.cntr p.statz, .dtt p.statz {
    font-weight: 600;
    font-size: 13px;
    color: #0246ff;
}

.cntr p.successful,
.cntr p.Successful, 
.dtt p.successful,
.dtt p.Successful {
    color: #0246ff;
}

.cntr p.pending, 
.cntr p.Pending, 
.dtt p.pending, 
.dtt p.Pending {
    color: var(--buttoned);
}

.cntr p.cancelled, 
.cntr p.Cancelled, 
.dtt p.cancelled, 
.dtt p.Cancelled {
    color: crimson;
}

.cntr p.reversed, 
.cntr p.Reversed, 
.dtt p.reversed, 
.dtt p.Reversed {
    color: #0246ff;
}

.rgt {
    display: grid;
    justify-items: center;
    align-content: space-between;
}

.rgt h3 {
    font-size: 13px;
    color: #fff;
    background: green;
    width: 60px;
    text-align: center;
    padding-top: 3px;
    border-radius: 10px;
}

.rgt h3.red {
    background: red;
}

.dtt p.red {
    color: darkred;
}

.dtt p.chrg{
    color: darkred
}

.dtt p.grn {
    color: green;
}

.rgt p.reptDets {
    font-size: 13px;
    text-decoration: underline;
    font-weight: 600;
    color: var(--pri-col);
    text-transform: uppercase;
}

.tsctn-dets {
    position: absolute;
    top: 0;
    width: 100vw;
    min-height: 75vh;
    background: var(--pri-col);
    padding: 25px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.detailed-trans {
    width: 80%;
    margin: 0 auto;
    background: #fff;
    padding: 20px;
    border-radius: 9px;
    border: 1px dotted;
}

.dtt {
    display: flex;
    justify-content: space-between;
    padding: 3px 0;
    border-bottom: 1px dotted darkgray;
    color: var(--pri-col);
}

.dtt:last-child {
    border-bottom: unset;
}

.dtt h4 {
    font-weight: 600;
    font-size: 13px;
}

.dtt p {
    font-weight: 600;
    font-size: 13px;
}

p.reptName {
    color: blue;
}

p.tID {
    color: darkslategray;
    font-weight: 500;
    font-family: cursive;
    font-size: 11px;
    letter-spacing: 0px;
}

h3.close {
    text-align: center;
    width: 95px;
    margin: 12px 0;
    background: red;
    padding-top: 3px;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
}

@media(max-width: 500px){
    .detailed-trans {
        width: 90%;
    }

    .lft h4 {
        font-size: 11px;
    }

    .lft p {
        font-size: 10px;
    }

    .cntr p.statz, .dtt p.statz {
        font-size: 11px;
    }

    .lft h4.rec-name {
        font-size: 12px;
    }

    .rgt p.reptDets {
        font-size: 11px;
    }

    .rgt h3 {
        font-size: 11px;
    }

    .mobile-trans {
        width: 90%;
    }
}

@media(max-width: 400px){
    .lft {
        max-width: 33%;
        overflow: hidden;
    }

    .lft h4 {
        font-size: 9px;
    }

    .lft p {
        font-size: 8px;
    }

    .lft h4.rec-name {
        font-size: 10px;
    }

    .cntr {
        max-width: 33%;
    }

    h3 {
        font-size: 12px;
    }

    .cntr p.statz {
        font-size: 10px;
    }

    .rgt {
        max-width: 33%;
    }

    .rgt h3 {
        font-size: 9px;
    }

    .dtt .idt {
        font-size: 9px;
        font-weight: 100;

    }
}