.auth {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    padding-top: 11vh;
    padding-bottom: 60px;
    align-items: center;
    /* background-image: url(../../dashboard/assets/auth.jpg);
    background-position: center;
    background-size: cover; */
    background: #1e222d;
}

.auth:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #0000002e;
}

.auth-container {
    width: 60%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}

.auth-container h2{
    color: #69b2ff;
    font-size: 21px;
    margin: 16px;
    text-transform: uppercase;
}

.auth-container .login,
.auth-container .register {
    width: 100%;
    height: fit-content;
    display: grid;
    align-items: center;
    /* background: #000000cc; */
    /* backdrop-filter: blur(4px); */
    align-content: center;
    border: 1px solid #69b2ff;
    border-radius: 13px;
    padding: 15px 6px;
    box-shadow: 0 3px 15px #2474c8;
    z-index: 99;
    background: rgb(255, 255, 255);
}

.auth-container .login form,
.auth-container .register form {
    margin: 0 10%;
}

 .form-cont {
    position: relative;
    width: 100%;
    height: 40px;
    margin: 18px 0;
}

 form input {
    width: 100%;
    height: 100%;
    border: none;
    border-bottom: 2px solid grey;
    font-size: 18px;
    background: transparent;
    color: #fff;
    padding: 0;
}

 form input:focus-visible{
    outline: none;
}

 form .form-cont .underline{
    position: absolute;
    bottom: 0;
    height: 2px;
    width: 100%;
}

form .form-cont .underline:before{
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    background: #ff9800;
    transform: scaleX(0);
    transition: transform 0.3s ease;
}

form .form-cont.invalid .underline:before{
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    background: crimson;
    transform: scaleX(0);
    transition: transform 0.3s ease;
}

form h6 {
    color: crimson;
    text-align: center;
}

button.btn-hover svg {
    line-height: 1;
    position: absolute;
    top: 0;
}

 form input:focus ~ .underline:before,
 form input:valid ~ .underline:before {
    transform: scaleX(1);
}



label.tnc {
    color: #000 !important;
}

label.tnc a {
    color: #b73300;
}

input#tnc {
    width: auto;
}

label.tnc {
    color: #fff !important;
    font-size: 12px;
}

.auth-container form button {
    width: 30%;
    font-size: 16px;
    margin: 2%;
    padding: 8px 4px;
    background: #2474c8;
    color: white;
    border-radius: 8px;
    display: block;
    align-self: flex-start;
}

.auth-container p {
    color: #000000;
    margin: 0% 0%;
}

.auth-container p span {
    color: #69b2ff;
    cursor: pointer;
}

.auth-container .register form input#tnc {
    width: initial;
    display: flex;
}

.auth-container .register form label a{
    color: #69b2ff;
}

.hideme{
    display: none !important;
}

@media (max-width: 800px) {
    .auth {
        margin: 21px 0;
    }

    .auth-container h2 {
        font-size: 21px;
        margin: 9px;
    }
}

@media (max-width: 500px) {
    .auth-container {
        width: 75%;
    }

    .auth-container h2 {
        font-size: 18px;
    }

    

.auth-container form button {
    font-size: 14px;
    margin: 2%;
    padding: 5px 2px;
}
}

@media (max-width: 400px) {
    .auth-container {
        width: 80%;
    }

    .auth-container h2 {
        font-size: 17px;
    }

    .auth-container .login, 
    .auth-container .register{
        background: #00000000;
        backdrop-filter: blur(2px);
        box-shadow: 0 3px 5px #2474c8;
    }
}

@media (max-width: 350px) {
    .auth-container {
        width: 85%;
    }    
    .auth-container h2 {
        font-size: 15px;
    }
}
